@charset "utf-8";

@use 'variables';

/*--------------------------------------------------------------
## 1279px以下
--------------------------------------------------------------*/

@media screen and (max-width: 1279px) {
  .global-nav {
    display: none;
  }

  /* component */
  :is(.top, .lower) .c-main-contents01 .contents_section-text {
    top: 0;
    bottom: auto;
    padding: 3rem;
  }

  :is(.top, .lower) .c-main-contents02 .c-main-contents02__box {
    width: 40%;
  }

  :is(.top, .lower) .c-main-contents02 .c-main-contents02__img {
    width: 50%;
  }
}

/*--------------------------------------------------------------
## 1024px以下
--------------------------------------------------------------*/

@media screen and (max-width: 1024px) {

  /* container */
  .container01 {
    width: calc(100% - 50px);
  }

  .container02 {
    width: calc(100% - 5vw);
  }

  /* bk-img ※画像のパララックスはiOSでは未対応のため */
  .bk-img {
    background-attachment: scroll;
    min-height: 300px;
  }

  /* component */
  :is(.top, .lower) {
    .c-main-contents01 {
      padding: 3rem 0 0;

      .contents_section {
        &-text {
          padding: 3rem 4rem;
          width: 38vw;
          min-height: 200px;
        }

        &:nth-child(1 of .contents_section) {
          &::after {
            bottom: -4.8vw;
          }
        }

        &:nth-child(2n of .contents_section) {
          &::before {
            bottom: -2.8vw;
          }
        }

        &:nth-child(3 of .contents_section) {
          &::after {
            bottom: -4.8vw;
          }
        }
      }
    }
  }

  /* toppage */
  .top {
    .contents_title {
      font-size: 3.5rem;
    }

    .contents01 {
      h2 {
        font-size: 1.8rem;
      }
    }

    .contents02 {
      .contents_section-text {
        top: auto;
        bottom: -380px;

        a {
          padding: 6rem 4rem;
        }
      }
    }

    .contents03 {
      padding: 6rem 0 0;

      .container a {
        margin-bottom: 5rem;
      }

      .contents_item {
        .article {
          a {
            .article-text {
              &_title {
                font-size: 2rem;
              }

              &_data {
                font-size: .8rem;

                &::before {
                  width: 2rem;
                }
              }
            }
          }
        }
      }
    }

    .contents04 {
      padding: 6rem 0 12rem;

      .contents_title {
        margin-bottom: 5rem;
      }

      .contents_item {
        .article {
          width: calc(100% - 4vw);
          padding: 0 2vw !important;

          .article-text {
            width: 100%;
            margin-bottom: 1rem;
          }

          .article-text_data {
            font-size: .8rem;
          }
        }
      }

      .more-btn {
        bottom: 9rem;
      }
    }

    .contents05 {
      padding: 5rem 0;

      .contents_title {
        margin-bottom: 2rem;
      }
    }
  }

  /* lower */
  .header_pageTitle h2 {
    padding-left: 5vw;
    font-size: 3.5rem;
  }

  /* service.php */
  .service {
    overflow-x: hidden;
  }

  /* archive(no sidebar) */
  .articles {
    .article {

      &_img {
        height: 30vw;
      }
    }
  }

  /* footer */
  .footer {

    .footer-box {
      &_text {
        p {
          font-size: .9rem;
          line-height: 1.7rem;
        }

        &-right {
          .sns-nav {
            &_list {
              li {
                font-size: 1.3rem;
              }
            }
          }
        }
      }
    }

    .copyright {
      font-size: .7rem;
    }
  }
}

/*--------------------------------------------------------------
## 959px以下
--------------------------------------------------------------*/

@media screen and (max-width: 959px) {
  .pc-only {
    display: none;
  }

  /* container */
  .container01,
  .container03 {
    width: calc(100% - 5vw);
  }

  /* component */
  :is(.top, .lower) {
    .c-main-contents01 {
      padding-top: 0;

      .container {
        width: 100%;
      }

      .contents_section {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 40rem;

        &:nth-child(2n of .contents_section) {
          flex-direction: column;
        }

        &-text {
          width: calc(85% - 5rem);
          margin-right: 10%;
          min-height: 0;
          top: 50vw;
          bottom: auto;

          h4 {
            font-size: 1.5rem;
          }
        }

        img {
          margin-left: 10%;
          width: 90%;
          height: 58vw;
        }

        &:nth-child(2n of .contents_section) {
          text-align: right;
        }
      }
    }
  }

  /* component */

  :is(.top, .lower) .c-main-contents02 .c-main-contents02__list {
    gap: 2rem;
  }

  :is(.top, .lower) .c-main-contents02 .c-main-contents02__item:is(:nth-child(odd), :nth-child(even)) {
    gap: 0;
  }

  :is(.top, .lower) .c-main-contents02 .c-main-contents02__item:is(:nth-child(odd), :nth-child(even)) .c-main-contents02__item--bg {
    display: none;
  }

  :is(.top, .lower) .c-main-contents02 .c-main-contents02__box {
    width: 100%;
    box-sizing: border-box;
    padding: 1.5rem;
    background-color: rgba(variables.$decoColor, 0.1);
  }

  :is(.top, .lower) .c-main-contents02 .c-main-contents02__img {
    width: 100%;
    aspect-ratio: 1.732 / 1;
  }

  :is(.top, .lower) .c-main-contents03 .c-main-contents03__img {
    width: 80%;
  }

  :is(.top, .lower) .c-main-contents03 .c-main-contents03__box {
    width: 100%;
    margin-top: 7%;
    box-sizing: border-box;
    padding-inline: 5%;
  }

  :is(.top, .lower) .c-main-contents03 .btn2 {
    margin-inline: auto;
  }

  /* btn */
  .btn1 {
    padding: 0.8rem 2rem;

    &::before {
      top: 5px;
      right: 5px;
    }
  }

  .btn2 {
    font-size: 1.5rem;
    padding: 0.8rem 2rem;

    &::before {
      top: 5px;
      right: 5px;
    }
  }

  /* header */
  .header-branding {
    top: 1rem;
    left: 1rem;
  }

  /* toppage */
  .top {
    .contents_title {
      font-size: 2.5rem;
    }

    .contents01 {
      padding: 7rem 0;

      h2 {
        font-size: 1.5rem;
      }
    }

    .contents02 {
      .contents_section {
        margin-bottom: 0;

        &-text {
          position: relative;
          width: 100%;
          min-height: 0;
          inset: auto;

          a {
            min-height: 0;
            padding-bottom: 9rem;

            h2 {
              font-size: 2.5rem;
            }

            .more-btn {
              right: 7rem;
              bottom: 3rem;
            }
          }
        }
      }
    }

    .contents03 {
      padding-top: 5rem;

      .container a {
        margin-bottom: 2rem;

        .more-btn {
          padding-left: 20rem;
        }
      }

      .contents_item {
        .article {
          a {
            .article-text {
              &_title {
                font-size: 1.5rem;
              }

              &_data {
                font-size: .7rem;
                margin: .8rem 0;

                &::before {
                  bottom: -.5rem;
                }
              }
            }
          }
        }
      }
    }

    .contents04 {
      padding-top: 5rem;

      .contents_item {
        .article {
          .article-text {
            column-gap: 1rem;
          }

          .article-text_title {
            grid-column: span 2;
          }

          .article-text_content {
            grid-column: span 2;
          }
        }
      }
    }
  }

  /* lower */
  .header_pageTitle h2 {
    font-size: 2.5rem;

    span {
      font-size: 1.5rem;
    }
  }

  /* service.php */
  .service {

    .contents01 {
      padding: 7rem 0;

      .contents_title {}
    }

    .contents03 {
      padding: 5rem 0;
      padding-bottom: 8rem !important;
    }
  }

  /* archive(no sidebar) */
  .articles {
    .article {
      width: 85%;
      margin: 0 auto 5rem;

      &_img {
        height: 52vw;
      }

      &:nth-child(odd) {
        margin-right: auto;
      }

      &:nth-child(even) {
        margin-left: auto;
      }
    }
  }

  .archive .contents01 {
    padding-bottom: 8rem !important;
  }

  /* sidebar */
  .widget-container {
    width: calc(270px - 2rem);
    margin-left: 2rem;

    &_title {
      font-size: 1.2rem;

      span {
        font-size: .7rem;
      }
    }

    ul {
      li {
        a {
          font-size: .9rem;
        }
      }
    }
  }

  /* archive(sidebar) */
  .archive.with-sidebar {

    .contents02 {
      padding-top: 0;
      padding-bottom: 15rem;

      .container {
        .articles {
          width: calc(100% - 270px);

          .article {
            width: 100%;

            &_img {
              height: 300px;

              img {
                object-fit: cover;
              }
            }

            &_text-title {
              font-size: 1.1rem;
            }
          }
        }
      }
    }
  }

  /* single(sidebar) */
  .single.with-sidebar {
    .contents02 {
      padding-bottom: 15rem;

      .container {
        .post {
          width: calc(100% - 270px);
          padding-right: 0;

          &_title {
            margin-bottom: 4rem;
            font-size: 1.8rem;
          }

          &_img {
            height: calc((100vw - 270px) * 0.65);
          }
        }
      }
    }
  }

  /* footer */
  .footer__cta {
    padding: 5rem 0;
    padding-bottom: 8rem !important;
  }

  .footer {
    .footer-box {
      &:first-child {
        width: 40%;
      }

      &:nth-child(2) {
        width: 56%;

        iframe {
          height: 400px;
        }
      }

      h2 {
        margin-bottom: 50px;
      }

      &_text {
        &-left {
          width: 100%;
          padding-right: 0;
          margin-bottom: 2rem;
        }

        &-right {
          width: 100%;

          .sns-nav {
            margin: 2rem 0 4rem;
          }
        }
      }
    }
  }
}

/*--------------------------------------------------------------
## 768px以下
--------------------------------------------------------------*/

@media screen and (max-width:768px) {
  /************* お問い合わせページ@768px *************/
  .p-contact-form .p-contact-form__table :is(th, td) {
    display: block;
    width: 100%;
    padding: 0.5rem;
  }
  .p-contact-form .p-contact-form__table th .th-inner {
    justify-content: flex-start;
  }
}

/*--------------------------------------------------------------
## 559px以下
--------------------------------------------------------------*/

@media screen and (max-width:559px) {

  /* component */
  :is(.top, .lower) {
    .c-main-contents01 {
      padding: 0 0 2rem;

      .contents_section {
        position: relative;
        margin-bottom: 5rem;
        display: flex;
        flex-direction: column-reverse;

        &-text {
          position: relative;
          padding: 2rem 1rem;
          width: calc(100% - 2rem);
          min-height: 0;
          margin-right: 0;
          margin-left: 0;
          top: 0;

          h4 {
            margin-bottom: 1.5rem;
          }
        }

        img {
          width: 100%;
          height: 65vw;
          margin-left: 0;
        }

        &::before,
        &::after {
          display: none;
        }

        &:nth-child(2n of .contents_section) {
          flex-direction: column;
        }
      }
    }
  }

  :is(.top, .lower) .c-main-contents03 .c-main-contents03__img {
    width: 100%;
  }
  
  /* lower */
  .header_pageTitle h2 {
    font-size: 1.7rem;
  }

  /* パンくずリスト */
  .breadcrumb {
    margin-bottom: 3rem;
  }

  /* table */
  .contents_table {

    th,
    td {
      padding: 1.5rem .5rem;
    }

    th {
      min-width: 5rem;
    }
  }

  /*ページャー*/
  .page-numbers li {

    .current,
    a {
      font-size: .8rem;
    }
  }

  /* toppage */
  .top {
    .contents01 {
      padding: 5rem 0;

      h2 {
        line-height: 3rem;
      }
    }

    .contents02 {
      .contents_section {
        margin-bottom: 5rem;

        &:last-child {
          margin-bottom: 0;
        }

        &-img {
          max-height: none;

          img {
            width: 100%;
            max-height: none;
            margin: 0;
          }
        }

        &-text {
          position: relative;
          width: 100%;
          top: -2px;

          a {
            padding: 3rem 1rem 6rem;

            h2 {
              font-size: 1.8rem;
              margin-bottom: 2rem;
            }

            .more-btn {
              left: auto;
              bottom: 4rem;
              right: 1rem;
            }

            &:hover .more-btn {
              left: auto;
              translate: 1rem;
            }
          }
        }

        &:first-child {
          .contents_section-img {
            &::before {
              border-right: 40vw solid transparent;
              border-bottom: 10vw solid rgba(0, 0, 0, 0.2);
            }

            &::after {
              border-left: 60vw solid transparent;
              border-right: none;
              border-top: none;
              border-bottom: 8vw solid rgba(0, 0, 0, 0.5);
              bottom: 0;
            }
          }
        }

        &:nth-child(2) {
          .contents_section-img {
            &::before {
              border-bottom: 10vw solid rgba(variables.$decoColor, 0.2);
            }

            &::after {
              border-top: 8vw solid transparent;
              border-bottom: none;
              border-right: 40vw solid rgba(variables.$decoColor, 0.4);
              bottom: 0;
            }
          }
        }
      }
    }

    .contents03 {
      padding: 5rem 0 8rem;

      .container a {
        margin-bottom: 0;
        min-height: 4.5rem;

        .more-btn {
          left: auto;
          bottom: 4rem;
          right: 1rem;
        }

        &:hover .more-btn {
          left: auto;
          translate: 1rem;
        }
      }

      .contents_item {
        .article {
          background-color: rgba(0, 59, 95, 0.3);
          width: 100%;
          height: 65vw;

          a {
            .article-text {
              display: block;

              &_title {
                padding-top: 22vw;
                font-size: 1.5rem;
                line-height: 2rem;
              }
            }
          }
        }
      }
    }

    .contents04 {
      padding: 5rem 0 8rem;

      .contents_title {
        margin-bottom: 3rem;
      }

      .contents_item {

        .article {
          margin-bottom: 2rem;
        }
      }

      .more-btn {
        right: 2rem;
        bottom: 6rem;

        &:hover {
          right: 1rem;
        }
      }
    }

    .contents05 {
      padding: 3.5rem 0;

      p {
        margin-bottom: 6rem;
      }

      .btn1 {
        bottom: 2rem;

        &::before {
          border-color: #fff;
        }
      }

      .btn2 {
        bottom: 2rem;

        &::before {
          border-color: #fff;
        }
      }
    }
  }

  /* about.php */
  .about {
    .contents01 {
      padding: 5rem 0;

      .contents_text {
        width: 100%;
        margin-bottom: 2rem;

        h3 {
          margin-bottom: 3rem;
        }

        p {
          padding-right: 0;
        }
      }

      .contents_img {
        width: 100%;

        img {
          width: 100%;
        }
      }
    }

    .contents02 {
      padding: 5rem 0 6rem;

      .container {
        padding: 2rem 0;
      }

      table {
        margin: 2rem .5rem 1rem;
      }
    }

    .contents03 {
      padding: 5rem 0 0;

      h3 {
        text-align: center;
      }

      table {
        margin: auto;
        margin-top: 5rem;
      }
    }
  }

  /* service.php */
  .service {
    .contents01 {
      padding: 5rem 0;
    }

    .contents03 {
      padding-top: 3rem;
      padding-bottom: 7rem !important;

      p {
        margin-bottom: 5rem;
      }
    }
  }
  
  /* requirements01.php */
  .requirements01 {

    .contents02 {
      padding: 5rem 0 6rem;

      .container {
        padding: 2rem 0;
      }

      table {
        margin: 2rem .5rem 1rem;
      }
    }
  }

  /* archive(no sidebar) */
  .articles {
    .article {
      width: 100%;

      &_img {
        height: 58vw;
      }
    }
  }

  .archive .contents01 {
    padding-top: 5rem;
  }

  /* single(no sidebar) */
  .single {
    .contents01 {
      padding-top: 5rem;
    }

    .contents02 {
      padding-bottom: 5rem !important;

      .post_title {
        margin: 4rem 0;
        font-size: 1.8rem;
      }
    }
  }

  /* sidebar */
  .widget-container {
    width: 100%;
    margin-left: 0;

    &_title {
      font-size: 1.5rem;

      span {
        font-size: .9rem;
      }
    }

    ul {
      li {
        a {
          font-size: 1rem;
        }
      }
    }
  }

  /* archive(sidebar) */
  .archive.with-sidebar {

    .contents02 {
      padding-bottom: 3rem;

      .container {
        display: block;

        .articles {
          width: 100%;
          margin-bottom: 10rem;

          .box_page-numbers {
            bottom: -2.5rem;
          }
        }
      }
    }
  }

  /* single(sidebar) */
  .single.with-sidebar {
    .contents02 {
      padding-bottom: 0;

      .container {
        display: block;

        .post {
          width: 100%;
          margin-bottom: 8rem;
          margin-right: 0;

          &_title {
            margin: 4rem 0;
          }

          &_img {
            height: 65vw;
          }
        }
      }
    }
  }
  

  /* footer */
  .footer__cta {
    padding-top: 3rem;
    padding-bottom: 7rem !important;

    p {
      margin-bottom: 5rem;
    }
  }

  .footer {
    padding: 3rem 0;

    .container {
      flex-direction: column-reverse;
      width: calc(100% - 2rem);

      .footer-box {
        width: 100%;

        iframe {
          width: 100%;
          margin-bottom: 2rem;
        }
      }

      .copyright {
        bottom: 2rem;
        text-align: center;
        margin: auto;
        left: 0;
        right: 0;
      }
    }
  }
}